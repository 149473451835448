const Login                     = () => import("@/views/Login");
const Dashboard                 = () => import("@/views/Dashboard");
const ForgotPassword            = () => import("@/views/password/Forgot");
const MatchIndex                = () => import("@/views/matches/Index");
const LedenIndex                = () => import("@/views/leden/Index");
const LedenInvite               = () => import("@/views/leden/Invite");
const CoachesIndex              = () => import("@/views/coaches/Index");
const TeamsIndex                = () => import("@/views/teams/Index");



const routes = [
    {
        path: '/',
        redirect: { name: 'login' }
    },
    
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/password/reset',
        name: 'password.forgot',
        component: ForgotPassword,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/wedstrijden',
        name: 'wedstrijden',
        component: MatchIndex,
    },
    {
        path: '/leden',
        name: 'leden',
        component: LedenIndex,
    },
    {
        path: '/leden/uitnodigen',
        name: 'ledenInvite',
        component: LedenInvite,
    },
    {
        path: '/coaches',
        name: 'coaches',
        component: CoachesIndex,
    },
    {
        path: '/teams',
        name: 'teams',
        component: TeamsIndex,
    },

]


export default routes